import React, { useState, useEffect } from 'react';
import ModuleNode from './modulenode';

const ModuleManagement = () => {
    const [search, setSearch] = useState("");
    const [moduleList, setModuleList] = useState([]);
    const [searchedList, setSearchedList] = useState([]);
    const [searchActive, setSearchActive] = useState(false);

    useEffect(() => {
        fetch("/api/v1/admin/get-modules")
        .then(response => response.json())
        .then(data => {
            setModuleList(data);
        });
    }, []);

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
        setSearchActive(true);
        let newModuleList = moduleList.filter(module =>
            module.title.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setSearchedList(newModuleList);
    };
// in the module list container we can increase maxHeight to a larger/smaller number to increase/decrease the size of the scroll
    return (
        <div className="manage-modules">
            <input 
                className="manage-modules-search" 
                placeholder="Search for Module" 
                value={search}
                onChange={handleSearchChange}
            />
            <div className="module-list-container" style={{ maxHeight: "600px", overflowY: "auto" }}> 
                {!searchActive && moduleList.map(m => (
                    <ModuleNode 
                        key={m.module_name}
                        moduleName={m.module_name}
                        title={m.title}
                        videoLink={m.video_link}
                        description={m.description} 
                    />
                ))} 
                {searchActive && searchedList.map(m => (
                    <ModuleNode 
                        key={m.module_name}
                        moduleName={m.module_name}
                        title={m.title}
                        videoLink={m.video_link}
                        description={m.description} 
                    />
                ))}
            </div>
        </div>
    );
};

export default ModuleManagement;
