import React, { useEffect, useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import CreatePage from './createpage';
import ModuleManagement from './modulemanagement';
import './admin.css';

const Admin = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    const [selectedModule, setSelectedModule] = useState("Create Page")
    const [createPageSelected, setCreatePageSelected] = useState(true)
    const [manageModulesSelected, setManageModulesSelected] = useState(false)

    useEffect(() => {
        fetch("/api/v1/login")
        .then(response => response.json())
        .then(data => {
            if(data["loggedIn"]) {
                setLoggedIn(true);
            }
            setLoading(false);
        });
    }, [])

    const logout = () => {
        fetch("/api/v1/logout")
        .then(response => response.json())
        .then(_ => {
            setLoggedIn(false)
        })
    }

    const selectCreatePage = () => {
        setSelectedModule("Create Page")
        setCreatePageSelected(true)
        setManageModulesSelected(false)
    }

    const selectManageModules = () => {
        setSelectedModule("Manage Modules")
        setManageModulesSelected(true)
        setCreatePageSelected(false)
    }

    if(loading) {
        return (
            <h1>Loading...</h1>
        )
    }
    else if(!loading && !loggedIn) {
        return (
            <Navigate to="/login" replace={true} />
        )
    }
    else if(!loading && loggedIn) {
        return (
            <div className="admin-page">
                <div className="admin-nav">
                    <button onClick={() => window.location.href = '/'} className="admin-nav-button">Home</button>
                    <button className="admin-nav-button" onClick={selectCreatePage}>Create Page</button>
                    <button className="admin-nav-button" onClick={selectManageModules}>Manage Modules</button>
                    <button className="admin-nav-button" onClick={logout}>Logout</button>
                    
                </div>
                <h1 className="admin-title">{selectedModule}</h1>
                {createPageSelected && <CreatePage />}
                {manageModulesSelected && <ModuleManagement />}
            </div>
        )
    }
}

export default Admin