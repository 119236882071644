import React, { useEffect } from 'react';

const EditArea = (props) => {
    useEffect(() => {
        props.onChange(props.default)
    }, [])

    const handleFieldChange = (event) => {
        props.onChange(event.target.value)
    }

    return(
        <div>
            <p>{props.label}</p>
            <textarea defaultValue={props.default} onChange={handleFieldChange} />
        </div>
    )
}

export default EditArea;