import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import EditField from './editfield';
import EditArea from './editarea';
import SuccessPopup from './successpopup';

import openLink from "../../assets/external-link-white.png"
import downloadIcon from "../../assets/download-icon.png"
import editIcon from "../../assets/edit-icon.png"

const ModuleNode = (props) => {
    const [title, setTitle] = useState(props.title)
    const [mediaLink, setMediaLink] = useState(props.videoLink)
    const [description, setDescription] = useState(props.description)

    // Editing State
    const [editing, setEditing] = useState(false);
    const [newTitle, setNewTitle] = useState("");
    const [newMediaLink, setNewMediaLink] = useState("");
    const [newDescription, setNewDescription] = useState("");
    const [editSuccess, setEditSuccess] = useState(false);
    
    const downloadQr = () => {
        fetch("/api/v1/admin/download-qr-code", {
            method: "POST",
            body: JSON.stringify({
                "moduleName" : props.moduleName,
                "origin" : window.location.origin
            })
        })
        .then(response => response.blob())
        .then(blob => {
            const file = document.createElement('a')
            file.href = URL.createObjectURL(blob)
            file.download = `${props.moduleName}_qrcode.png`
            document.body.appendChild(file) 
            file.click()
            document.body.removeChild(file)
        })
    }

    const edit = () => {
        if(!editing) {
            setEditing(true)
        } else {
            setEditing(false)
        }
    }

    const saveChanges = () => {
        setTitle(newTitle)
        setMediaLink(newMediaLink)
        setDescription(newDescription)
        fetch("/api/v1/admin/edit-module", {
            method: "POST",
            body: JSON.stringify({
                moduleName: props.moduleName,
                title: newTitle.trim(),
                videoLink: newMediaLink.trim(),
                description: newDescription.trim()
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if(data && data.success === true) {
                setEditing(false)
                setEditSuccess(true)
                setTimeout(() => {
                    setEditSuccess(false)
                }, 1000)
            } else {
                setEditing(false)
            }
        })
    }

    return(
        <div className="module-node">
            <div className="module-config">
                <Link to={`/module/${props.moduleName}`} target="_blank" rel="noopener noreferrer">
                    <img className="open-module" src={openLink} alt="Error" />
                </Link>
                <img className="edit-module" src={editIcon} onClick={edit} alt="Error" />
            </div>
            
            {editing && <EditField label="Title" default={title} onChange={setNewTitle} />}
            {editing && <EditField label="Media Link" default={mediaLink} onChange={setNewMediaLink} />}
            {editing && <EditArea label="Description" default={description} onChange={setNewDescription} />}
            {editing && <button onClick={saveChanges}>Save</button>}
            {editing && <button onClick={() => {setEditing(false)}}>Cancel</button>}

            {!editing && editSuccess && <SuccessPopup />}
            {!editing && <h1>{title}</h1>}
            {!editing && <button onClick={downloadQr}>Download QR Code <img className="download-qr" src={downloadIcon} alt="Error" /></button>}
        </div>
    )
}

export default ModuleNode