import React from 'react';

const SuccessPopup = () => {
    return (
        <div className="success-popup">
            <p>Module edited successfully</p>
        </div>
    )
}

export default SuccessPopup;